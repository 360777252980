import {createRouter, createWebHistory} from 'vue-router'

const router = createRouter({
   history: createWebHistory(process.env.BASE_URL),
   routes: [
      {path: '/', name: 'Login',meta:{title: 'Login'}, component: () => import(/* webpackChunkName: "Login" */ '../views/Login.vue')},
      {
         path: '/dash',
         name: 'Dash',
         component: () => import(/* webpackChunkName: "Dash" */ '../views/Dash.vue'),
         children: [

            {
               path: '/principal',
               name: 'Principal',
               meta:{title: 'Principal'},
               component: () => import(/* webpackChunkName: "about" */ '../views/Principal.vue')
            },
            {
               path: '/painel',
               name: 'Painel',
               meta:{title: 'Painel'},
               component: () => import(/* webpackChunkName: "Painel" */ '../views/Painel.vue')
            },
            // CADASTRO
            {
               path: '/cliente',
               name: 'Cliente',
               meta:{title: 'Clientes'},
               component: () => import(/* webpackChunkName: "about" */ '../views/Cadastros/cliente/Cliente.vue')
            },
            {
               path: '/clientes/cadastrar',
               name: 'ClienteCadastrar',
               meta:{title: 'Clientes'},
               component: () => import(/* webpackChunkName: "about" */ '../views/Cadastros/cliente/ClienteCadastro.vue')
            },
            {
               path: '/clientes/cadastrar/:item',
               name: 'ClienteCadastrarId',
               meta:{title: 'Clientes'},
               component: () => import(/* webpackChunkName: "about" */ '../views/Cadastros/cliente/ClienteCadastro.vue')
            },
            {
               path: '/licenca',
               name: 'Licença',
               meta:{title: 'Licenças'},
               component: () => import(/* webpackChunkName: "about" */ '../views/Cadastros/licenca/Licenca.vue')
            },
            {
               path: '/sistema',
               name: 'Sistema',
               meta:{title: 'Softwares'},
               component: () => import(/* webpackChunkName: "about" */ '../views/Cadastros/sistema/Sistema.vue')
            },
            {
               path: '/usuario',
               name: 'Usuário',
               meta:{title: 'Usuários'},
               component: () => import(/* webpackChunkName: "about" */ '../views/Cadastros/usuarios/Usuario.vue')
            },
            {
               path: '/auditoria',
               name: 'Auditoria',
               meta:{title: 'Auditoria'},
               component: () => import(/* webpackChunkName: "about" */ '../views/auditoria/Auditoria.vue')
            },
            {
               path: '/error_log',
               name: 'ErrorLog',
               meta:{title: 'ErrorLog'},
               component: () => import(/* webpackChunkName: "about" */ '../views/error_log/ErrorLog.vue')
            },
            {
               path: '/error_log/:error_log_id',
               name: 'ErrorLog/id',
               meta:{title: 'Error_Log'},
               component: () => import(/* webpackChunkName: "about" */ '../views/error_log/ErroLogView.vue')
            },
            {
               path: '/protocolos',
               name: 'Protocolos',
               meta:{title: 'Protocolos'},
               component: () => import(/* webpackChunkName: "about" */ '../views/error_log_agrupado/Protocolos.vue')
            },
            // {
            //    path: '/error_log/:error_log_id',
            //    name: 'ErrorLog/id',
            //    meta:{title: 'Error_Log'},
            //    component: () => import(/* webpackChunkName: "about" */ '../views/error_log/ErrorLog.vue')
            // },
            {
               path: '/visualizacao_acesso',
               name: 'Visualização de Acesso',
               meta:{title: 'Visual. de Acesso'},
               component: () => import(/* webpackChunkName: "about" */ '../views/acessos/Acessos.vue')
            },
            {
               path: '/backup',
               name: 'Backup',
               meta:{title: 'Backup'},
               component: () => import(/* webpackChunkName: "backup" */ '../views/backup/BackupClientes.vue')
            },
            {
               path: '/backup/:pasta',
               name: 'Backup/Pasta',
               meta:{title: 'BackupPasta'},
               component: () => import(/* webpackChunkName: "backup" */ '../views/backup/Backup.vue')
            },
            {
               path: '/backup/:pasta/:subpasta',
               name: 'Backup/Pasta/Arquivos',
               meta:{title: 'BackupPasta'},
               component: () => import(/* webpackChunkName: "backup" */ '../views/backup/Backup.vue')
            },
            {
               path: '/relatorio_versao',
               name: 'Versao',
               meta:{title: 'Versao'},
               component: () => import(/* webpackChunkName: "about" */ '../views/relatorio/RelatorioVersao.vue')
            },
            {
               path: '/atualizar',
               name: 'Atualizar',
               meta:{title: 'Atualizar'},
               component: () => import(/* webpackChunkName: "about" */ '../views/atualizar/Atualizar.vue')
            },

         ]
      },
      {
         path: '/:pathMatch(.*)*',
         name: 'not-found',
         component: () => import(/* webpackChunkName: "404" */ '../views/NotFound/NotFound.vue')
      },

   ]
});

router.beforeEach((to, from) => {
   document.title = (to.meta?.title + ' - ' + process.env.VUE_APP_TITLE)  ?? process.env.VUE_APP_TITLE
})
export default router
