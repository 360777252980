/* eslint-disable */
import {Filtro} from "../models/filtro/filtro";


export const  getErrorLogSCH = (campo, valor, condicao)=>{
  var filtro = new Filtro()
  filtro.perPage = 1
  filtro.filters.addFilter(campo,valor,condicao)
  filtro.fields = ['*']
  return filtro
}


export const ErrorLogSCH = (perPage)=> {
  var filtro = new Filtro()
  filtro.perPage = perPage
  filtro.fields = ['*']
  filtro.filters.addFilter('status','N','=')
  // filtro.filters.addFilterRaw(" message NOT ILIKE '% ORIGEM%' ")

  // filtro.filters.addFilter('message','| ORIGEM','<>')
  // filtro.addOrder('qtd_protocol','desc')
  filtro.addOrder('dh_error','desc')

  return filtro
}


export const ErrorLogAgrupadoSCH = (perPage)=> {
  var filtro = new Filtro()
  filtro.perPage = perPage
  filtro.fields = ['protocol','message','is_resolvido','qtd_protocol','status','resolved_in','dh_error','strack_trace']
  // filtro.filters.addFilter('status','N','=')
  // filtro.addOrder('qtd_protocol','desc')
  filtro.addOrder('dh_error','desc')

  return filtro
}
