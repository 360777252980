/* eslint-disable */
import {lista, processar, pegaLista, pegaDados, processaDados, nao_autorizado} from '../../services/GenericAPI'
import {ClienteSCH, getClienteSCH} from "../../search/ClienteSCH"
import {Filtro, Join, Left, Where} from "../../models/filtro/filtro";
import {Cliente} from '../../models/cliente'
import {EventBus} from '../../helpers/event-bus'

const url = '/cliente'

const state = {
  all: [],
  allList: [],
  allListCliSoft: [],
  cliente: new Cliente(),
  filtro: new Filtro()
}

const getters = {
  listaAllClientesSoftware: (state) => {
    return state.allListCliSoft
  },
  listaAllClientes: (state) => {
    return state.allList
  },

  listaClientes: (state) => {
    return state.all
  },
  pegaCliente: (state) => {
    return state.cliente
  },
  pegaFiltro: (state) => {
    return state.filtro
  }
}

const actions = {
  searchItem({commit}, search) {
    state.filtro.filters.search = [];
    state.filtro.filters.addSearch([
      '&unaccent(clientes.razao)',
      '&unaccent(clientes.fantasia)',
      '&unaccent(clientes.contato)',
      'cnpj_cpf'
    ], "&'%' || unaccent('"+search+"') || '%'", 'ilike')
  },
  async getItem({commit}, {cliente_id, onSucesso,onErro}) {
    try {
    var filtro = getClienteSCH('cliente_id', cliente_id, '=')
    const list = (await pegaDados(url+'/get', filtro)).data
      if (list.data[0] !== undefined) {
        commit('SET_CLIENTE', list.data[0])
        // setTimeout(function() {
          onSucesso()
        // },500)
        // onSucesso()
      } else {
        onErro()
      }
    } catch (error) {
      onErro()
      console.log(error)
    }
  },
  async getModulo({commit}, {cliente_id, onSucesso,onErro}) {
    try {
    var filtro = getClienteSCH('cliente_id', cliente_id, '=')
    const list = (await pegaDados(url+'/get_modulo', filtro)).data
      if (list.data[0] !== undefined) {
        onSucesso(list.data[0])
      } else {
        onErro()
      }
    } catch (error) {
      onErro()
      console.log(error)
    }
  },

  setFiltro({commit}, perPage) {
    commit('SET_FILTRO', ClienteSCH(perPage))
  },

  async setAllListClientes({commit}) {
    const list = (await lista(url + '/all')).data
    commit('SET_ALL_LIST', list)
  },
  async setAllListClientesSoftware({commit}) {
    const list = (await lista(url + '/cliente_software')).data
    commit('SET_ALL_LIST_CLI_SOF', list)
  },
  async setAllClientes({commit},{onSucesso, onErro}) {
    try {
      const list = (await pegaDados(url + '/get', state.filtro)).data
      commit('SET_CLIENTES', list)
      onSucesso()
    } catch (error) {
      console.log(error)
      onErro()
      nao_autorizado(error)
    }
  },

  async commitBloqueio({commit},{item, onSucesso, onErro}) {
    try {
      const res = (await processaDados(url + '/commit_bloqueio', item))
      this.dispatch('geral/setMsgSuccess', res.data.message)
      onSucesso()
    } catch (error) {
      console.log(error)
      this.dispatch('geral/setObjMsgError', error.response.data)
      onErro()
    }
  },

  async processarCliente({commit}, {acao,onSucesso,onErro}) {
    try {
      let res = await processar(url, state.cliente, acao)

      this.dispatch('geral/setMsgSuccess', res.data.message)
      onSucesso()
    } catch (error) {
      onErro()
      console.log('error: ',error)
      this.dispatch('geral/setObjMsgError', error.response.data)
    }
  },
  async deleteSoftware({commit}, {item, onSucesso,onErro}) {
    try {
      let res = await processar(url + '/delete_software', item, 'remover')
      this.dispatch('geral/setMsgSuccess', res.data.message)
      onSucesso()
    } catch (error) {
      console.log('error: ',error)
      this.dispatch('geral/setObjMsgError', error.response.data)
      onErro()
    }
  },
  setCliente({commit}, cliente) {
    commit('SET_CLIENTE', cliente)
  },
  removeSoftware({commit}, Software) {
    commit('REMOVE_SOFTWARE', Software)
  },
  async alteraSoftware({commit},  {item,tipo, onSucesso,onErro}) {
    try {
      if (tipo==='editando') {
        let res = await processar(url + '/commit_cli_software', item, 'gravar')
        // this.dispatch('geral/setMsgSuccess', res.data.message)
      } else {
        commit('ALTERA_SOFTWARE', item)
      }
      onSucesso()
    } catch (error) {
      console.log('error: ',error)
      this.dispatch('geral/setObjMsgError', error.response.data)
      onErro()
    }
  },
  adicionaSoftware({commit}, Software) {
    commit('ADICIONA_SOFTWARE', Software)
  }
}

const mutations = {
  SET_ALL_LIST_CLI_SOF(state, all) {
    state.allListCliSoft = all
  },
  SET_ALL_LIST(state, filtro) {
    state.allList = filtro
  },
  SET_FILTRO(state, filtro) {
    state.filtro = filtro
  },
  SET_CLIENTES(state, clientes) {
    state.all = clientes
  },
  SET_CLIENTE(state, cliente) {
    state.cliente = cliente
  },
  REMOVE_SOFTWARE(state, software) {
    let pos = state.cliente.clientes_softwares.indexOf(software)
    state.cliente.clientes_softwares.splice(pos, 1)
  },
  ALTERA_SOFTWARE(state, software) {
    let c = state.cliente.clientes_softwares.find(list=>list.cliente_software_id === software.cliente_software_id)
    let pos = state.cliente.clientes_softwares.indexOf(c)
    state.cliente.clientes_softwares.splice(pos, 1, software)
  },
  ADICIONA_SOFTWARE(state, software) {
    state.cliente.clientes_softwares.push(software)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
